import '../styles/helpNewStyles.css'
import * as Strings from '../utils/Strings'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/Store';
import { getData } from '../hooks/HomeScreenHooks';
import { setError } from '../redux/GoodWillReducer';
import { Actions } from '../redux/Actions';
import { useEffect, useState } from 'react';
import { HomeScreenResponseModel } from '../models/HomeResponseModel';
import loadingImage from '../assets/images/icon_loading.gif'
import icon_error from '../assets/images/icon_error.png'
import goodwillLogo from '../assets/images/icon_good_will.png'
import careerNavigator from '../assets/images/icon_career_navigator.png'
import sendMeAText from '../assets/images/icon_send_me_a_text.png';

import useBreadcrumbs from "use-react-router-breadcrumbs";
import Hamburger from 'hamburger-react';
import Drawer from '../components/Drawer';
import { Breadcrumbs } from '@mui/material';
import Link from '@mui/material/Link';
import { FiChevronRight } from 'react-icons/fi';
import { FaAsterisk } from 'react-icons/fa'
import { setClarityTag } from '../utils/ClarityUtils';
import FullScreenDialog from '../components/FullScreenDialog';

function HelpNew() {

    const { category, subcategory } = useParams();

    const navigate = useNavigate();

    const dispatch = useDispatch()
    const isOnline = navigator.onLine

    const { loading, error, homeScreenData } = useSelector((state: RootState) => state.home);

    const breadcrumbs = useBreadcrumbs();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const breadCrumbs = useBreadcrumbs(undefined, { defaultFormatter: (x) => decodeURIComponent(x) })

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const [subcategoryList, setSubcategoryList] = useState<HomeScreenResponseModel[] | undefined>(undefined)
    const [subCategoryItem, setSubcategoryItem] = useState<HomeScreenResponseModel | undefined>(undefined)

    const [isDialogOpen, setDailogOpen] = useState(false)

    useEffect(() => {

        const decodedCategory = decodeURIComponent(category ?? '')
        const decodedSubCategory = decodeURIComponent(subcategory ?? '')

        setClarityTag('page', 'Details: ' + decodedCategory + " " + decodedSubCategory);
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const decodedCategory = decodeURIComponent(category ?? '')
        const decodedSubCategory = decodeURIComponent(subcategory ?? '')
        const categoryItem = homeScreenData?.find((item: HomeScreenResponseModel) => item.title.toLowerCase() == decodedCategory?.toLowerCase())
        const subcategoryList = homeScreenData?.filter((item: HomeScreenResponseModel) => item.parentId == categoryItem?.id?.toString())
        const subCategoryItem = subcategoryList?.find((item: HomeScreenResponseModel) => item.title.toLowerCase().trim() == decodedSubCategory?.toLowerCase().trim())
        setSubcategoryList(subcategoryList)
        setSubcategoryItem(subCategoryItem)
    }, [homeScreenData, category, subcategory])



    useEffect(() => {
        if (homeScreenData === undefined && isOnline) {
            getData(dispatch)
        } else if (!isOnline) {
            dispatch(setError({ errorMessage: Strings.networkErrorMessage, operation: Actions.FETCH_DATA }))
        }
    }, [])

    return (
        <div>
            <div className="section-header">
                <img src={goodwillLogo} alt={''} className='logo' />
                <div className='career-navigator-container'>
                    {subCategoryItem?.isCareerNavigator == 1 && <img src={careerNavigator} alt={''}
                        className='career-navigator-img' onClick={navigateToCareerNavigator} />}
                    {subCategoryItem?.isText == 1 && <img src={sendMeAText} alt={''} onClick={showFullScreenDialog}
                        className='send-me-a-text-img' />}
                    {homeScreenData && <div>
                        <Hamburger size={28} color="#094f98" onToggle={onToggle} toggled={isMenuOpen} />
                    </div>}
                </div>
            </div>
            {renderBreadCrumbs()}
            {renderDetails()}
            <FullScreenDialog open={isDialogOpen} onClose={onDialogClose} context={`${decodeURIComponent(category ?? '')}.${decodeURIComponent(subcategory ?? '')}`} />
        </div >
    );


    function showFullScreenDialog() {
        setDailogOpen(true)
    }

    function onDialogClose() {
        setDailogOpen(false)
    }

    function navigateToCareerNavigator() {
        const decodedCategory = decodeURIComponent(category ?? '')
        const decodedSubCategory = decodeURIComponent(subcategory ?? '')
        navigate(`/${decodedCategory}/${decodedSubCategory}/career-navigator`, { state: { from: `${decodedCategory}.${decodedSubCategory}` } })
    }

    function onToggle(toggled: boolean) {
        setIsMenuOpen(toggled)
        if (toggled === true) {
            window.scrollTo(0, 0)
        }
    }

    function onMenuItemClick(title: string, id: number) {
        setIsMenuOpen(false)
        const decodedCategory = decodeURIComponent(category ?? '')
        navigate(`/${decodedCategory}/${title}`)
    }


    function renderDetails() {
        if (loading) {
            return renderProgressBar()
        } else if (error) {
            return renderError()
        } else {
            return renderContent()
        }
    }

    function renderBreadCrumbs() {
        return (
            <Breadcrumbs style={{ padding: '6px', backgroundColor: '#094f98' }} aria-label="breadcrumb" separator={<FiChevronRight size='20' color='white' />}>
                {breadCrumbs.map(({ breadcrumb, key, location }, index) => {
                    if (index === breadCrumbs.length - 1) {
                        return <div className='breadcrumb-text'>{breadcrumb}</div>
                    } else {
                        return <div className='breadcrumb-text-link' onClick={() => navigate(key)}>{breadcrumb}</div>
                    }
                })}
            </Breadcrumbs>
        )
    }

    function renderContent() {
        const bulletPoints = subCategoryItem?.bulletText.split('\n')
        const formattedText = bulletPoints?.filter((text) => text.trim() !== '')
        return (
            <div className='detail-container'>
                {windowWidth > 768 ? renderTitleCard(subCategoryItem?.title) : renderMobileViewTitleCard(subCategoryItem?.title)}
                <div>
                    <div className='detail-description'>{subCategoryItem?.description}</div>
                    {formattedText?.map((text, index) => (
                        <div className='bullet-container'>
                            <div className='index-container'>
                                <FaAsterisk size={22} color='#fff' />
                            </div>
                            <div className='detail-bullets'>{text}</div>
                        </div>
                    ))}
                    {(subCategoryItem?.careerCenterLocations && subCategoryItem.careerCenterLocations.length > 0 && subCategoryItem.isShowLocation == 1) && renderCareerCenterLocations()}
                    {(subCategoryItem?.isQrCode == 1 || subCategoryItem?.isBrowser == 1) && <Link children={<div onClick={openUrl}>Read More...</div>} variant='subtitle2' color={'#fff'} style={{ fontFamily: 'nunito_regular', fontSize: '24px', width: '100%', justifyContent: 'flex-end', display: 'flex', cursor: 'pointer' }} />}
                </div>
                <Drawer show={isMenuOpen} menuItems={subcategoryList} onMenuItemClick={onMenuItemClick} />
            </div>
        );
    }

    function renderCareerCenterLocations() {
        return (
            <div>
                <div className='detail-career-center-title'>{Strings.careerCenterLocations}</div>
                {subCategoryItem?.careerCenterLocations.map((location, index) => (
                    <div className='career-center-location-container'>
                        <div className='career-center-location-bullet-container'>
                            <FaAsterisk size={22} color='#fff' />
                        </div>
                        <div>
                            <div className='career-center-locations'>{location.name}</div>
                            {location.addressLine1 && <div className='career-center-locations'>{location.addressLine1}</div>}
                            {location.addressLine2 && <div className='career-center-locations'>{location.addressLine2}</div>}
                            {location.city && <div className='career-center-locations'>{`${location.city}, ${location.state}, ${location.zipCode}`}</div>}
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    function openUrl() {
        window.open(subCategoryItem?.url, '_blank')
    }

    function renderMobileViewTitleCard(title?: string) {
        return (
            <div className='detail-mobile-view-title-card-container'>
                <div className='detail-left-line-mobile-view' />
                <div className='detail-title-mobile-view' >{title}</div>
                <div className='detail-right-line-mobile-view' />
            </div>
        );
    }

    function renderTitleCard(title?: string) {
        return (
            <div className='detail-title-card-container'>
                <div className='detail-left-line' />
                <div className='detail-title' >{title}</div>
                <div className='detail-right-line' />
            </div>
        );
    }

    function renderProgressBar() {
        return (
            <div className='container-with-center-alignment'>
                <img src={loadingImage} alt={''} className='error-img' />
                <div className='loading-text'>{Strings.fetchingData}</div>
            </div>
        )
    }

    function renderError() {
        return (
            <div className='container-with-center-alignment' >
                <img src={icon_error} alt={''} className='error-img' />
                <div className='error-text' >{error ? error?.errorMessage : Strings.commonError}</div>
            </div>
        )
    }
}
export default HelpNew;
