import { COMMON_ERROR, TIME_OUT } from "../api/ApiConstants";
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles'
import { BreadcrumbData } from "use-react-router-breadcrumbs";

export function getHeader() {
    return { timeout: TIME_OUT }
}

export function getError(error: any) {
    if (error?.response?.error?.hasError) {
        return error.response
    } else {
        return COMMON_ERROR
    }
}

export function getErrorFromModel(responseModel: any) {
    if (responseModel.error?.hasError === true) {
        return { errorMessage: responseModel?.error?.messages ? responseModel?.error?.messages[0] : COMMON_ERROR }
    } else {
        return responseModel
    }
}

export const StyledTextField = styled(TextField)({
    width: '100%',

    marginBottom: '12px',
    '& .MuiInputBase-input': {
        fontFamily: 'nunito_regular',
        fontSize: '18px',
        color: '#094f98',
    },
    '& .MuiFormLabel-root': {
        fontFamily: 'nunito_regular',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#094f98',
        },
        '&:hover fieldset': {
            borderColor: '#094f98',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#094f98',
        },
    },
});

function renderBreadCrumbs(breadCrumbs: BreadcrumbData[]) {
    const breadCrumbsWithoutSubcategoryPath = breadCrumbs.filter((breadCrumb) => breadCrumb.breadcrumb?.toString() !== '/subcategory')

}