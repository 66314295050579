import { useEffect } from "react"
import { setError, setHomeScreenData, setLoading } from "../redux/GoodWillReducer"
import { fetchResponse } from "../api/GoodWillApi"
import { getErrorFromModel } from "../utils/Helper"
import { Actions } from "../redux/Actions"

export const useGetData = (dispatch: any, isConnected: boolean = false) => {
    useEffect(() => {
        if (isConnected === true) {
            getData(dispatch)
        }
    }, [isConnected])

    return getData
}

export async function getData(dispatch: any) {
    dispatch(setLoading(true))
    const homeScreenResponse = await fetchResponse()
    const error = getErrorFromModel(homeScreenResponse)
    dispatch(typeof homeScreenResponse === 'string' ? setError({ errorMessage: homeScreenResponse, operation: Actions.FETCH_DATA }) : (error.errorMessage ? setError(error) : setHomeScreenData(homeScreenResponse)))
    dispatch(setLoading(false))
}