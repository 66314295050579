import '../styles/subCategoryStyles.css'
import * as Strings from '../utils/Strings'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/Store';
import { getData } from '../hooks/HomeScreenHooks';
import { setError } from '../redux/GoodWillReducer';
import { Actions } from '../redux/Actions';
import { useEffect, useState } from 'react';
import { HomeScreenResponseModel } from '../models/HomeResponseModel';
import loadingImage from '../assets/images/icon_loading.gif'
import icon_error from '../assets/images/icon_error.png'
import goodwillLogo from '../assets/images/icon_good_will.png'
import { BASE_URL } from '../api/ApiConstants';
import careerNavigator from '../assets/images/icon_career_navigator.png'
import Hamburger from 'hamburger-react'
import Drawer from '../components/Drawer';
import { FiChevronRight } from "react-icons/fi";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Breadcrumbs } from '@mui/material';
import { setClarityTag } from '../utils/ClarityUtils';



function SubCategoryNew() {

    const { category } = useParams()
    const [categoryId, setCategoryId] = useState(0)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const navigate = useNavigate();

    const dispatch = useDispatch()
    const isOnline = navigator.onLine

    const { loading, error, homeScreenData } = useSelector((state: RootState) => state.home);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const breadCrumbs = useBreadcrumbs(undefined, { defaultFormatter: (x) => decodeURIComponent(x) })

    useEffect(() => {
        const decodedCategory = decodeURIComponent(category ?? '')
        setClarityTag('page', 'SubCategory: ' + category);
        const categoryItem = homeScreenData?.find((item: HomeScreenResponseModel) => item.title.toLowerCase() === decodedCategory?.toLowerCase())
        setCategoryId(categoryItem?.id ?? 0)

    }, [homeScreenData])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (homeScreenData === undefined && isOnline) {
            getData(dispatch)
        } else if (!isOnline) {
            dispatch(setError({ errorMessage: Strings.networkErrorMessage, operation: Actions.FETCH_DATA }))
        }
    }, [])

    return (
        <div style={{ overflow: 'hidden' }} >
            <div className="section-header">
                <img src={goodwillLogo} alt={''} className='logo' />
                <div className='career-navigator-container'>
                    <img src={careerNavigator} alt={''} className='career-navigator-img' onClick={navigateToCareerNavigator} />
                    {homeScreenData && <div>
                        <Hamburger size={28} color="#094f98" onToggle={onToggle} toggled={isMenuOpen} />
                    </div>}
                </div>
            </div>
            {renderBreadCrumbs()}
            {renderSubCategories()}
        </div>
    );

    function renderBreadCrumbs() {
        const category = homeScreenData?.find((item: HomeScreenResponseModel) => item.id === categoryId)
        return (
            <Breadcrumbs style={{ padding: '6px', backgroundColor: '#094f98' }} aria-label="breadcrumb" separator={<FiChevronRight size='20' color='white' />}>
                {breadCrumbs.map(({ breadcrumb, key, location }, index) => {
                    if (index === breadCrumbs.length - 1) {
                        return <div className='breadcrumb-text'>{category?.title}</div>
                    } else {
                        return <div className='breadcrumb-text-link' onClick={() => navigate(key)}>{breadcrumb}</div>
                    }
                })}
            </Breadcrumbs>
        )
    }

    function onToggle(toggled: boolean) {
        setIsMenuOpen(toggled)
        if (toggled === true) {
            window.scrollTo(0, 0)
        }
    }

    function renderSubCategories() {
        if (loading) {
            return renderProgressBar()
        } else if (error) {
            return renderError()
        } else {
            return windowWidth > 768 ? renderContent() : renderMobileView()
        }
    }

    function renderMobileView() {
        const category = homeScreenData?.find((item: HomeScreenResponseModel) => item.id == categoryId)
        const menuItems = homeScreenData?.filter((item: HomeScreenResponseModel) => item.parentId == null)
        const subCategories = homeScreenData?.filter((item: HomeScreenResponseModel) => {
            return item.parentId == category?.id.toString()
        })
        return (
            <div style={{ overflowX: 'hidden' }}>
                <div style={{
                    backgroundImage: ` url(${BASE_URL}/${category?.image}`,
                    backgroundSize: 'cover',
                    width: '100%',
                    height: '30vh',

                }} >
                </div>
                {renderMobileViewCards(subCategories, category?.title)}
                <Drawer show={isMenuOpen} menuItems={menuItems} onMenuItemClick={onMenuItemClick} />
            </div>
        )
    }

    function renderMobileViewCards(subCategoryItems: HomeScreenResponseModel[] | undefined, title?: string) {
        return (
            <div className='mobile-view-card-container'>
                {renderMobileViewTitleCard(title)}
                {subCategoryItems?.map((item, index) => (
                    <div
                        className='mobile-view-card'
                        onClick={() => onCardClick(item)}
                    >
                        <img src={BASE_URL + '/' + item.image} alt={''} className='mobile-view-img' />
                        <div className='mobile-view-text-container'>
                            <div className='mobile-view-title-text'>{item.title}</div>
                            <div className="mobile-view-subtitle-text">
                                {item.subTitle}
                            </div>
                        </div>
                        <FiChevronRight size={30} className='chevron-icon' />
                    </div>
                ))}
            </div>
        );
    }

    function renderContent() {
        const category = homeScreenData?.find((item: HomeScreenResponseModel) => item.id === categoryId)
        const menuItems = homeScreenData?.filter((item: HomeScreenResponseModel) => item.parentId === null)
        return <div style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${BASE_URL}/${category?.image})`,
            backgroundSize: 'cover',
        }}>
            <div className='container'>
                {renderTitleCard(category?.title)}
                <div style={{ width: '80%', alignSelf: 'center' }}>
                    {renderElements()}
                </div>
            </div>
            <Drawer show={isMenuOpen} menuItems={menuItems} onMenuItemClick={onMenuItemClick} />
        </div>
    }

    function onMenuItemClick(title: string, id: number) {
        setCategoryId(id)
        setIsMenuOpen(false)
        navigate(`/${title}`, { replace: true });
    }

    function renderTitleCard(title?: string) {
        return (
            <div className='title-card-container'>
                <div className='left-line' />
                <div className='title' >{title}</div>
                <div className='right-line' />
            </div>
        );
    }

    function renderMobileViewTitleCard(title?: string) {
        return (
            <div className='mobile-view-title-card-container'>
                <div className='mobile-view-left-line' />
                <div className='mobile-view-title' >{title}</div>
                <div className='mobile-view-right-line' />
            </div>
        );
    }

    function renderElements() {
        const subCategories = homeScreenData?.filter((item: HomeScreenResponseModel) => item.parentId == categoryId.toString())
        return (
            <div className='subcategory-card-container'>
                {subCategories?.map((item, index) => (
                    renderCard(item, index)
                ))
                }
            </div >
        )
    }

    function renderProgressBar() {
        return (
            <div className='container-with-center-alignment'>
                <img src={loadingImage} alt={''} className='error-img' />
                <div className='loading-text'>{Strings.fetchingData}</div>
            </div>
        )
    }

    function renderError() {
        return (
            <div className='container-with-center-alignment' >
                <img src={icon_error} alt={''} className='error-img' />
                <div className='error-text' >{error ? error?.errorMessage : Strings.commonError}</div>
            </div>
        )
    }

    function renderCard(item: HomeScreenResponseModel, index: number) {
        return <div className='subcategory-card' onClick={() => { onCardClick(item) }}>
            <img src={BASE_URL + '/' + item.image} alt={''} className='subcategory-card-img' />
            <div className='subcategory-card-title'>{item.title}</div>
            <div className='subcategory-card-subtitle'>{item.subTitle}</div>
        </div>
    }

    function onCardClick(item: any) {
        const category = homeScreenData?.find((item: HomeScreenResponseModel) => item.id === categoryId)
        navigate(`/${category?.title}/${item.title}`)
    }

    function navigateToCareerNavigator() {
        const category = homeScreenData?.find((item: HomeScreenResponseModel) => item.id === categoryId)
        navigate(`/${category?.title}/career-navigator`, { state: { from: category?.title } })
    }
}
export default SubCategoryNew;
