// src/useClarity.ts
import { useEffect } from 'react';

const useClarity = () => {
    useEffect(() => {

        (function (c: any, l: Document, a: string, r: string, i: string) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
            const t = l.createElement(r) as HTMLScriptElement;
            t.async = true;
            t.src = "https://www.clarity.ms/tag/" + i;
            const y = l.getElementsByTagName(r)[0] as Element;
            y.parentNode!.insertBefore(t, y);
        })(window, document, "clarity", "script", "mtyeeb7f7u");
    }, []);
};

export default useClarity;
