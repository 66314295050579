import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CommonErrorModel } from '../models/CommonErrorModel'
import { HomeScreenResponseModel } from '../models/HomeResponseModel'


export interface HomeScreenState {
    loading: boolean,
    error?: CommonErrorModel,
    homeScreenData?: HomeScreenResponseModel[],
}

export const initialState: HomeScreenState = {
    loading: true,
}

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload
        },
        setError: (state, { payload }: PayloadAction<CommonErrorModel | undefined>) => {
            state.error = payload
        },
        setHomeScreenData: (state, { payload }: PayloadAction<HomeScreenResponseModel[] | undefined>) => {
            state.homeScreenData = payload
        },
        resetHomeScreenData: (state) => {
            state.loading = false
            state.error = undefined
            state.homeScreenData = undefined
        }
    },
})

export const { setLoading, setError, setHomeScreenData, resetHomeScreenData } = homeSlice.actions
export default homeSlice.reducer