import * as React from "react";
import { HomeScreenResponseModel } from "../models/HomeResponseModel";
import '../styles/drawerStyles.css';
import icon_training from '../assets/images/icon_training_menu.png'
import icon_employment from '../assets/images/icon_employment.png'
import icon_opportunuties from '../assets/images/icon_opp.png'
import icon_support from '../assets/images/icon_support.png'
import { BASE_URL } from "../api/ApiConstants";

interface DrawerProps {
    show: boolean;
    menuItems?: HomeScreenResponseModel[];
    onMenuItemClick: (title: string, id: number) => void;
}

const Drawer = (drwaerProps: DrawerProps) => {

    const { show, menuItems, onMenuItemClick } = drwaerProps;
    const icons = [icon_opportunuties, icon_training, icon_employment, icon_support]

    return (
        <div style={{ left: show ? window.innerWidth - 350 : window.innerWidth, backgroundColor: '#094f98', width: show ? 350 : 0, position: 'absolute', top: 0, overflow: 'hidden' }}>
            {renderMenuItems(menuItems)}
        </div>
    );

    function renderMenuItems(menuItems?: HomeScreenResponseModel[]) {
        return (
            <div className="menu-container">
                {menuItems?.map((item, index) => (
                    <div className="menu-item-container">
                        <div style={{ width: '50px', height: '50px' }}>
                            <img src={item.parentId === null ? icons[index] : `${BASE_URL}/${item.image}`} style={{ width: '100%', height: '100%', objectFit: item.parentId === null ? 'contain' : 'contain' }} />
                        </div>
                        <div className="menu-item" key={index} onClick={() => onMenuItemClick(item.title, item.id)} style={{ padding: '12px', color: 'white' }}>
                            {item.title}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
};

export default Drawer;
