import '../styles/helpNewStyles.css'
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { MdClose, MdPhone } from "react-icons/md";
import goodwillLogo from '../assets/images/icon_good_will.png'
import { submitPhoneNumberTitle, actions, submitMobileNUmberSuccessMessage } from '../utils/Strings'
import { StyledTextField } from '../utils/Helper'
import { Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/Store';
import { resetSubmitMobileNumberState } from '../redux/MobileNumberReducer';
import IconSuccess from '../assets/images/icon_success.png'
import { setClarityTag } from '../utils/ClarityUtils';
import { CareerNavigatorRequestModel } from '../models/CareerNavigatorRequestModel';
import { submitMobileNumber } from '../hooks/SubmitMobileNumberApiHooks';


interface IProps {
    open: boolean;
    onClose: () => void;
    context: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({ open, onClose, context }: IProps) => {

    const [isOpen, setOpen] = React.useState(open);
    const [isNumberError, setNumberError] = React.useState(false)
    const [mobileNumber, setMobileNumber] = React.useState('')
    const [showErrorDialog, setShowErrorDialog] = React.useState(false)

    const { loading, error, requestResponse } = useSelector((state: RootState) => state.submitMobileNumber);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setOpen(open)
    }, [open])

    React.useEffect(() => {
        setClarityTag('page', 'Submit Mobile Number');
        dispatch(resetSubmitMobileNumberState())
    }, [])

    React.useEffect(() => {
        if (error !== undefined) {
            setShowErrorDialog(true)
        }
    }, [error])

    const handleClose = () => {
        dispatch(resetSubmitMobileNumberState())
        resetForm()
        setOpen(false);
        onClose();
    };

    function onMobileNumberSubmit() {
        if (mobileNumber.length === 10 && mobileNumber.match(/^[0-9]+$/)) {
            const careerNavigatorRequest: CareerNavigatorRequestModel = { phone: mobileNumber, context: context, type: 'sms', id: 0, name: '', email: '', message: '' }
            submitMobileNumber(dispatch, careerNavigatorRequest)
        } else {
            setNumberError(true)
        }
    }

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={isOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: '#094f98' }}>
                    <Toolbar >
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <MdClose size={24} color='#fff' />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {requestResponse ? showSuccessMessage() : renderForm()}
                {showErrorMessage()}
            </Dialog>
        </React.Fragment>
    );

    function renderForm() {
        return (
            <div style={{ padding: '32px' }}>
                <div className='logo-container'>
                    <img src={goodwillLogo} alt={''} className='dialog-logo' />
                </div>
                <div className='submit-phonenumber-title'>{submitPhoneNumberTitle}</div>

                <StyledTextField
                    id="outlined-basic-name"
                    label="Mobile Number"
                    variant="outlined"
                    required
                    autoComplete='off'
                    value={mobileNumber}
                    inputMode='text'
                    error={isNumberError}
                    helperText={isNumberError ? 'Please enter a valid mobile number' : ''}
                    onChange={(e) => {
                        setMobileNumber(e.target.value)
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <MdPhone color='#094f98' size={22} />
                            </InputAdornment>
                        )
                    }}
                />

                <div className='mobilenumber-submit-button' onClick={onMobileNumberSubmit}>
                    {loading === true && <CircularProgress size={22} color='inherit' style={{ alignSelf: 'center', marginRight: '6px' }} />}
                    <div>{actions.submit}</div>
                </div>
            </div>
        )
    }

    function showSuccessMessage() {
        return (
            <div className='career-navigator-form'>
                <img style={{ width: '50px', height: '50px', alignSelf: 'center', marginTop: '22px', marginBottom: '22px' }} src={IconSuccess} alt={''} />
                <div className='career-navigator-form-title'>{submitMobileNUmberSuccessMessage}</div>
            </div>
        )
    }

    function showErrorMessage() {
        return (
            <Dialog
                onClose={clearErrorState}
                open={showErrorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Submission Failed"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {error ? error?.errorMessage : 'Something went wrong while submitting your request. Please try again later.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={clearErrorState}>OK</Button>
                </DialogActions>
            </Dialog>
        )
    }

    function clearErrorState() {
        setShowErrorDialog(false)
        resetForm()
        if (error?.operation == 'SUBMIT_MOBILE_NUMBER') {
            dispatch(resetSubmitMobileNumberState())
        }
    }

    function resetForm() {
        setMobileNumber('')
        setNumberError(false)
    }
}

export default FullScreenDialog;