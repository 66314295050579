import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import SubCategoryNew from './pages/SubCategoryNew';
import HelpNew from './pages/HelpNew';
import { Provider } from 'react-redux';
import { Store } from './redux/Store';
import HomeNew from './pages/HomeNew';
import CareerNavigator from './pages/CareerNavigator';
import useClarity from "./hooks/UseClarity";

function App() {

  useClarity()

  return (
    <Provider store={Store}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={< HomeNew />} />
            < Route path="/:category" element={< SubCategoryNew />} />
            < Route path="/:category/:subcategory" element={< HelpNew />} />
            < Route path={"/career-navigator"} element={< CareerNavigator />} />
            < Route path={"/:category/career-navigator"} element={< CareerNavigator />} />
            < Route path={"/:category/:subcategory/career-navigator"} element={< CareerNavigator />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
