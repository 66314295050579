import { useEffect } from "react"
import { setError, setCareerNavigatorResponse, setLoading } from "../redux/CareerNavigatorReducer"
import { fetchResponse, postCareerNavigatorRequest } from "../api/GoodWillApi"
import { getErrorFromModel } from "../utils/Helper"
import { Actions } from "../redux/Actions"
import { CareerNavigatorRequestModel } from "../models/CareerNavigatorRequestModel"


export const usePostRequest = (dispatch: any, isConnected: boolean = false, careerNavigatorRequestModel: CareerNavigatorRequestModel) => {
    useEffect(() => {
        if (isConnected === true) {
            postData(dispatch, careerNavigatorRequestModel)
        }
    }, [isConnected])

    return postData
}

export async function postData(dispatch: any, careerNavigatorRequestModel: CareerNavigatorRequestModel) {
    dispatch(setLoading(true))
    const requestResponse = await postCareerNavigatorRequest(careerNavigatorRequestModel)
    if (requestResponse.status === 200) {
        const error = getErrorFromModel(requestResponse)
        dispatch(typeof requestResponse === 'string' ? setError({ errorMessage: requestResponse, operation: Actions.POST_DATA }) : (error.errorMessage ? setError(error) : setCareerNavigatorResponse(requestResponse)))
    } else {
        dispatch(setError({ errorMessage: 'Server Error', operation: Actions.POST_DATA }))
    }
    dispatch(setLoading(false))
}