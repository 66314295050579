import '../styles/careerNavigatorStyles.css';
import goodwillLogo from '../assets/images/icon_good_will.png'
import BackgroundImage from '../assets/images/jobresources.png'
import * as Strings from '../utils/Strings'
import { useEffect, useState } from 'react';
import { StyledTextField } from '../utils/Helper'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment } from '@mui/material';
import { FiUser, FiPhone, FiMail, FiMessageSquare, FiChevronRight } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { CareerNavigatorRequestModel } from '../models/CareerNavigatorRequestModel';
import { useDispatch, useSelector } from 'react-redux';
import { postData } from '../hooks/CareerNavigatorApiHooks';
import { RootState } from '../redux/Store';
import IconSuccess from '../assets/images/icon_success.png'
import { resetCareerNavigatorState } from '../redux/CareerNavigatorReducer';
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Breadcrumbs } from '@mui/material';
import { setClarityTag } from '../utils/ClarityUtils';

function CareerNavigator() {

    const navigate = useNavigate();
    const location = useLocation();
    const screenContext = location.state?.from

    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState(screenContext)
    const [isNameError, setIsNameError] = useState(false)
    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false)
    const [isEmailError, setIsEmailError] = useState(false)
    const [isMessageError, setIsMessageError] = useState(false)

    const [showErrorDialog, setShowErrorDialog] = useState(false)

    const dispatch = useDispatch()
    const { loading, error, requestResponse } = useSelector((state: RootState) => state.careerNavigator);

    const breadCrumbs = useBreadcrumbs(undefined, { defaultFormatter: (x) => decodeURIComponent(x) })

    useEffect(() => {
        setClarityTag('page', 'Career Navigator: ' + screenContext);
        dispatch(resetCareerNavigatorState())
    }, [])

    useEffect(() => {
        if (error !== undefined) {
            setShowErrorDialog(true)
        }
    }, [error])

    useEffect(() => {
        return () => {
            if (location.state?.from === 'CareerNavigator') {
                dispatch(resetCareerNavigatorState())
            }
        };
    }, [location]);

    return (
        <div>
            <div className="section-header">
                <img src={goodwillLogo} alt={''} className='logo' />
            </div>
            {renderBreadCrumbs()}
            {renderCareerNavigator()}
            {showErrorMessage()}
        </div>
    )

    function renderBreadCrumbs() {
        return (
            <Breadcrumbs style={{ padding: '6px' }} aria-label="breadcrumb" separator={<FiChevronRight size='20' color='white' />}>
                {breadCrumbs.map(({ breadcrumb, key }, index) => {
                    if (index === breadCrumbs.length - 1) {
                        return <div className='breadcrumb-text'>{Strings.careerNavigator}</div>
                    } else {
                        return <div className='breadcrumb-text-link' onClick={() => navigate(key)}>{breadcrumb}</div>
                    }
                })}
            </Breadcrumbs>
        )
    }



    function showErrorMessage() {
        return (
            <Dialog
                onClose={clearErrorState}
                open={showErrorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Goodwill Career Navigator"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {error ? error?.errorMessage : 'Something went wrong while submitting your request. Please try again later.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={clearErrorState}>OK</Button>
                </DialogActions>
            </Dialog>
        )
    }

    function clearErrorState() {
        setShowErrorDialog(false)
        resetCareerNavigatorForm()
        if (error?.operation === 'POST_DATA') {
            dispatch(resetCareerNavigatorState())
        }
    }

    function showSuccessMessage() {
        return (
            <div className='career-navigator-form'>
                <img style={{ width: '50px', height: '50px', alignSelf: 'center', marginTop: '22px', marginBottom: '22px' }} src={IconSuccess} alt={''} />
                <div className='career-navigator-form-title'>{Strings.careerNavigatorSuccessMessage}</div>
            </div>
        )
    }

    function renderCareerNavigatorForm() {
        return <div className='career-navigator-form'>
            <div className='career-navigator-form-title'>{Strings.careerNavigator}</div>
            <div className='career-navigator-form-subtitle'>{Strings.careerNavigatorMessage}</div>

            <StyledTextField
                id="outlined-basic-name"
                label="Name"
                variant="outlined"
                required
                autoComplete='off'
                value={name}
                helperText='Please enter your name.'
                inputMode='text'
                error={isNameError}
                onChange={(e) => {
                    setName(e.target.value)
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <FiUser color='#094f98' size={22} />
                        </InputAdornment>
                    )
                }}
            />
            <StyledTextField
                id="outlined-basic-number"
                label="Mobile Number"
                variant="outlined"
                required
                autoComplete='off'
                helperText='Please enter valid mobile number.'
                value={phoneNumber}
                inputMode='tel'
                error={isPhoneNumberError}
                onChange={(e) => setPhoneNumber(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <FiPhone color='#094f98' size={22} />
                        </InputAdornment>
                    )
                }}
            />

            <StyledTextField
                id="outlined-basic-email"
                label="Email"
                inputMode='email'
                value={email}
                autoComplete='off'
                error={isEmailError}
                helperText='Please enter valid email address.'
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <FiMail color='#094f98' size={22} />
                        </InputAdornment>
                    )
                }}
            />

            <StyledTextField
                id="outlined-multiline-static-message"
                label="Message"
                multiline
                autoComplete='off'
                rows={4}
                value={message}
                error={isMessageError}
                variant="outlined"
                fullWidth
                onChange={(e) => setMessage(e.target.value)}
                required
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <FiMessageSquare color='#094f98' size={22} />
                        </InputAdornment>
                    )
                }}
            />

            <div className=' career-navigator-button' onClick={onCareerNavigatorButtonClick}>
                {loading === true && <CircularProgress size={22} color='inherit' style={{ alignSelf: 'center', marginRight: '6px' }} />}
                <div>{Strings.actions.submit}</div>
            </div>
        </div>
    }

    function renderCareerNavigator() {
        return (
            <div className="career-navigator-form-container">
                <div className='img-container2 desktopOnly'>
                    <img src={BackgroundImage} alt={''} />
                </div>
                <div className='vertical-line desktopOnly' />
                {requestResponse === undefined ? renderCareerNavigatorForm() : showSuccessMessage()}
            </div>
        )
    }

    function resetCareerNavigatorForm() {
        setName('')
        setPhoneNumber('')
        setEmail('')
        setMessage('')
        setIsNameError(false)
        setIsPhoneNumberError(false)
        setIsEmailError(false)
        setIsMessageError(false)
    }


    function isValidEmail(email: string) {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }

    function isNumericPhoneNumber(phoneNumber: string) {
        const regex = /^[0-9]*$/;
        return regex.test(phoneNumber);
    }

    function onCareerNavigatorButtonClick() {
        if (name === '' || phoneNumber === '' || !isNumericPhoneNumber(phoneNumber) || email === '' || !isValidEmail(email) || message === '') {
            if (name === '') setIsNameError(true)
            if (phoneNumber === '' || !isNumericPhoneNumber(phoneNumber)) setIsPhoneNumberError(true)
            if (email === '' || !isValidEmail(email)) setIsEmailError(true)
            if (message === '') setIsMessageError(true)
        } else {
            const careerNavigatorRequest: CareerNavigatorRequestModel = { id: 0, name, phone: phoneNumber, email, message, context: screenContext, type: undefined }
            postData(dispatch, careerNavigatorRequest)
        }
    }
}
export default CareerNavigator;
