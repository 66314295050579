import { useState, useEffect } from 'react';
import '../styles/homeNewStyles.css'
import * as Strings from '../utils/Strings'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/Store';
import { getData } from '../hooks/HomeScreenHooks';
import { HomeScreenResponseModel } from '../models/HomeResponseModel';
import { Actions } from '../redux/Actions';
import { setError } from '../redux/GoodWillReducer';
import goodwillLogo from '../assets/images/icon_good_will.png'
import careerNavigator from '../assets/images/icon_career_navigator.png'
import icon_error from '../assets/images/icon_error.png'
import loadingImage from '../assets/images/icon_loading.gif'
import { BASE_URL } from '../api/ApiConstants';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import BackgroundImage from '../assets/images/slide5.jpg'
import { setClarityTag } from '../utils/ClarityUtils';



function HomeNew() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        setClarityTag('page', 'Home');
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const navigate = useNavigate();

    const dispatch = useDispatch()
    const isOnline = navigator.onLine

    const { loading, error, homeScreenData } = useSelector((state: RootState) => state.home);

    useEffect(() => {
        if (homeScreenData === undefined && isOnline) {
            getData(dispatch)
        } else if (!isOnline) {
            dispatch(setError({ errorMessage: Strings.networkErrorMessage, operation: Actions.FETCH_DATA }))
        }
    }, [])

    return (
        <div>
            <div className="section-header">
                <img src={goodwillLogo} alt={''} className='logo' />
                <div className='career-navigator-container'>
                    <img src={careerNavigator} alt={''} className='career-navigator-img' onClick={navigateToCareerNavigator} />
                </div>
            </div>
            {renderHomeScreen()}
        </div>
    );

    function renderHomeScreen() {
        if (loading) {
            return renderProgressBar()
        } else if (error) {
            return renderError()
        } else {
            return windowWidth > 768 ? renderCardWithBackground() : renderCard()
        }
    }

    function renderCarousel() {
        const data = homeScreenData?.filter((item) => item.parentId === null)
        return (
            <div>
                <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} showStatus={false} >
                    {data?.map((item, index) =>
                        <div style={{
                            backgroundImage: `url(${BASE_URL}/${item?.image}`,
                            backgroundSize: 'cover',
                            height: '86vh',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: 'column',
                        }} onClick={() => onCardClick(item)}>
                            <div style={{ backgroundColor: '#000', opacity: '0.6', padding: '12px', marginBottom: '2.5%', marginLeft: '28px', marginRight: '28px', borderTopLeftRadius: '15px', borderBottomRightRadius: '15px', display: 'flex', flexDirection: 'column' }}>
                                <div className='carousel-subtitle'>{item.subTitle}</div>
                                <div className='carousel-title'>{item.title}</div>
                            </div>
                        </div>
                    )}
                </Carousel>
            </div>
        )
    }

    function renderProgressBar() {
        return (
            <div className='container-with-center-alignment'>
                <img src={loadingImage} alt={''} className='error-img' />
                <div className='loading-text'>{Strings.fetchingData}</div>
            </div>
        )
    }

    function renderError() {
        return (
            <div className='container-with-center-alignment' >
                <img src={icon_error} alt={''} className='error-img' />
                <div className='error-text' >{error ? error?.errorMessage : Strings.commonError}</div>
            </div>
        )
    }

    function renderCardWithBackground() {

        const data = homeScreenData?.filter((item) => item.parentId === null)
        return (
            <div style={{ position: 'relative', height: '85vh', }}>
                <div style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${BackgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: '55vh',
                }} />
                <div className='good-will-container' style={{ backgroundColor: 'transparent', position: 'absolute', bottom: 0, paddingBottom: '12px', paddingTop: '12pz', marginLeft: '1%' }}>
                    {data?.map((item, index) =>
                        <div className='card' key={index} onClick={() => onCardClick(item)} >
                            <img src={`${BASE_URL}/${item.image}`} alt={''} />
                            <div className='card-body'>
                                <div className='card-subtitle'>{item.subTitle}</div>
                                <div className='card-title'>{item.title}</div>
                            </div>
                        </div >
                    )
                    }
                </div>
            </div>
        )
    }

    function renderCard() {
        const data = homeScreenData?.filter((item) => item.parentId === null)
        return (
            <div style={{ overflow: 'auto' }}>
                <div className='good-will-container'>
                    {data?.map((item, index) =>
                        <div className='card' key={index} onClick={() => onCardClick(item)} >
                            <img src={`${BASE_URL}/${item.image}`} alt={''} />
                            <div className='card-body'>
                                <div className='card-subtitle'>{item.subTitle}</div>
                                <div className='card-title'>{item.title}</div>
                            </div>
                        </div >
                    )
                    }
                </div>
            </div>
        )
    }

    function onCardClick(item: HomeScreenResponseModel) {
        navigate(`/${item.title}`, { state: { id: item.id } })
    }

    function navigateToCareerNavigator() {
        navigate('/career-navigator', { state: { from: 'Home' } })
    }
}

export default HomeNew;
