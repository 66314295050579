import { configureStore } from '@reduxjs/toolkit'
import GoodWillReducer from './GoodWillReducer'
import CareerNavigatorReducer from './CareerNavigatorReducer'
import MobileNumberReducer from './MobileNumberReducer'

export const Store = configureStore({
    reducer: {
        home: GoodWillReducer,
        careerNavigator: CareerNavigatorReducer,
        submitMobileNumber: MobileNumberReducer
    },
})

export type RootState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch
