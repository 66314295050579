import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CommonErrorModel } from '../models/CommonErrorModel'
import { ResponseModel } from '../models/ResponseModel'


export interface careerNavigatorState {
    loading: boolean,
    error?: CommonErrorModel,
    requestResponse?: ResponseModel,
}

export const initialState: careerNavigatorState = {
    loading: false,
}

export const careerNavigatorSlice = createSlice({
    name: 'careerNavigator',
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload
        },
        setError: (state, { payload }: PayloadAction<CommonErrorModel | undefined>) => {
            state.error = payload
        },
        setCareerNavigatorResponse: (state, { payload }: PayloadAction<ResponseModel | undefined>) => {
            state.requestResponse = payload
        },
        resetCareerNavigatorState: (state) => {
            state.loading = false
            state.error = undefined
            state.requestResponse = undefined
        }
    },
})

export const { setLoading, setError, setCareerNavigatorResponse, resetCareerNavigatorState } = careerNavigatorSlice.actions
export default careerNavigatorSlice.reducer