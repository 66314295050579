export const emptyString = ''
export const header = 'It\'s time to Connect. What\'s on your mind?'
export const footerText = '(c) 2024 Goodwill Connect'
export const actions = {
    goBack: '<< Go Back',
    home: 'Home',
    cancel: 'Cancel',
    proceed: 'Proceed',
    submit: 'Submit',
}
export const networkErrorMessage = 'No Internet Connection'
export const careerNavgatorLabel = 'Need help developing a career plan? '
export const fetchingData = 'Fetching data please wait...'
export const commonError = 'Something went wrong. Please try again later.'
export const careerNavigatorButtonText = 'Connect with a Goodwill Career Navigator'
export const home = 'Home '
export const slash = '>'
export const careerNavigator = 'Career Navigator'
export const careerNavigatorMessage = 'Goodwill Career Navigators will chart your course to employment success with personalized support and skill building resources.'
export const careerNavigatorSuccessMessage = 'Your request has been successfully submitted.\n\nA Goodwill Career Navigator will connect with you within 48 hours.'
export const scanQRcode = 'Scan QR code shown below to learn more about Goodwill Connect'

export const submitPhoneNumberTitle = 'Please provide your mobile number to send the text. By entering your mobile number, you consent to receiving the text message from Goodwill.'

export const submitMobileNUmberSuccessMessage = 'Your request has been successfully submitted.'
export const careerCenterLocations = 'Our Career Center Locations: '  