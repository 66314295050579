import axios from "axios";
import *  as API_CONSTANTS from './ApiConstants'

axios.defaults.baseURL = API_CONSTANTS.BASE_URL
axios.defaults.timeout = API_CONSTANTS.TIME_OUT
axios.defaults.timeoutErrorMessage = "timed out sorry"
const myInstance = axios.create({ timeout: API_CONSTANTS.TIME_OUT });

const guaranteeTimeout = (config: any) => {
    config.cancelToken = new axios.CancelToken((cancelRequest) => {
        setTimeout(() => cancelRequest('Optional message'),
            API_CONSTANTS.TIME_OUT);
    });
    return config;
};

myInstance.interceptors.request.use(guaranteeTimeout);

export function getInstance() {
    return myInstance;
}