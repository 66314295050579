import { getError } from "../utils/Helper"
import *  as API_CONSTANTS from './ApiConstants'
import { HomeScreenResponseModel } from '../models/HomeResponseModel'
import { getInstance } from "./ApiHelper"

export async function fetchResponse() {
    const myInstance = getInstance()
    myInstance.defaults.timeout = API_CONSTANTS.TIME_OUT;
    myInstance.defaults.headers.common['Content-Type'] = 'application/json';
    myInstance.defaults.headers.common['Accept'] = 'application/json';
    myInstance.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbiIsImp0aSI6IjEzNmEzYWNmLTUxYmUtNDA1My05NzQ2LTkwYzIwYmY1YTVhMCIsImV4cCI6MTcwNTIyNjU5MiwiaXNzIjoiYWRtaW5jb25uZWN0Lmdvb2R3aWxsZGUub3JnIiwiYXVkIjoiYWRtaW5jb25uZWN0Lmdvb2R3aWxsZGUub3JnIn0.9u3FDnKQ7-TNOvVrZ9M2A_sT_eU00cT4X78yR-NdO5k';
    const response = await myInstance.get<HomeScreenResponseModel[]>(API_CONSTANTS.HOME_URL).then(function (response) {
        return response.data
    }).catch(function (error) {
        return getError(error)
    });
    return response
}

export const postCareerNavigatorRequest = async (careerNavigatorRequestModel: any) => {
    const myInstance = getInstance()
    myInstance.defaults.timeout = API_CONSTANTS.TIME_OUT;
    myInstance.defaults.headers.common['Content-Type'] = 'application/json';
    myInstance.defaults.headers.common['Accept'] = 'application/json';
    myInstance.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbiIsImp0aSI6IjEzNmEzYWNmLTUxYmUtNDA1My05NzQ2LTkwYzIwYmY1YTVhMCIsImV4cCI6MTcwNTIyNjU5MiwiaXNzIjoiYWRtaW5jb25uZWN0Lmdvb2R3aWxsZGUub3JnIiwiYXVkIjoiYWRtaW5jb25uZWN0Lmdvb2R3aWxsZGUub3JnIn0.9u3FDnKQ7-TNOvVrZ9M2A_sT_eU00cT4X78yR-NdO5k';
    const response = await myInstance.post(API_CONSTANTS.CAREER_NAVIGATOR_URL, careerNavigatorRequestModel).then(function (response) {
        return response
    }).catch(function (error) {
        return getError(error)
    });
    return response
}

